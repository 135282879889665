<template>
  <div class="widgetTemplates-page">
    <BackTitle class="mb-40" @click="goBack">Widget Templates</BackTitle>
    <Guard permission="widgetTemplates.create" v-slot="{ isAvailable }">
      <IconButton
        v-if="isAvailable"
        icon="plus"
        isRaised
        class="mb-40"
        @click="goToCreation"
      >
        Add new widget template
      </IconButton>
    </Guard>
    <div class="widgetTemplates-page__content">
      <Loader v-if="isLoading" size="m" color="primary" class="mx-auto" />
      <div v-else-if="!widgetTemplates.length" class="empty">
        You haven't created widget templates yet
      </div>
      <Table
        v-else
        name="widgetTemplatesTable"
        :columns="tableColumns"
        :rows="widgetTemplates"
        :menuOptions="menuOptions"
        @on-view-click="handleOpenWidgetTemplate"
        @menu-select="handleMenuSelect"
        has-settings
      />
    </div>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle.vue";
import Guard from "@/components/common/Guard.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import Table from "@/components/table/Table.vue";
import { getWidgetTemplatesTableColumns } from "@/helpers/widgetTemplatesTable";
import alert from "@/plugins/alert";
import { generateWidgetCode, generateWidgetPreviewLink } from "@/helpers/utils";

export default {
  name: "WidgetTemplatesPage",
  components: { Table, Guard, BackTitle },
  data() {
    return {
      isLoading: false,
      menuOptions: [
        {
          name: "Copy code",
          icon: "clipboard",
          value: "copy",
        },
        {
          name: "Open preview window",
          icon: "link",
          value: "preview",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      venue: (state) => state.venues.selectedVenue,
      widgetTemplates: (state) => state.widgetTemplates.list,
      widgetTemplatesTableColumns: (state) =>
        state.users.venueUserJunction?.widgetTemplatesTableColumns,
    }),
    ...mapGetters({
      checkPermission: "users/checkPermission",
    }),
    tableColumns() {
      return getWidgetTemplatesTableColumns(this.widgetTemplatesTableColumns);
    },
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchWidgetTemplates();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchWidgetTemplates: "widgetTemplates/fetchWidgetTemplates",
    }),
    goBack() {
      this.$router.push({
        name: "Settings",
      });
    },
    goToCreation() {
      this.$router.push({
        name: "CreateWidgetTemplate",
      });
    },
    handleOpenWidgetTemplate(id) {
      this.$router.push({
        name: "EditWidgetTemplate",
        params: {
          id,
        },
      });
    },
    async handleMenuSelect({ option, row }) {
      if (option.value === "copy") {
        const code = generateWidgetCode({
          venueId: this.venue.id,
          widgetTemplateId: row.id,
          widgetTheme: this.venue.widget,
        });
        await navigator.clipboard.writeText(code);
        alert.open({ message: "Code copied" });
      } else if (option.value === "preview") {
        const previewLink = generateWidgetPreviewLink({
          venueId: this.venue.id,
          widgetTemplateId: row.id,
        });
        window.open(previewLink, "__blank");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.widgetTemplates-page__content {
  display: flex;
  flex-direction: column;
}
</style>
