import { getColumns } from "@/helpers/table";
import store from "@/store";

export const WIDGET_TEMPLATES_TABLE_DEFAULT_COLUMNS = [
  {
    label: "Name",
    field: "name",
    id: "name",
    isHidden: false,
  },
  {
    label: "Product",
    field: (widgetTemplate) => {
      if (!widgetTemplate.productId) {
        return "All";
      }
      return (
        store.state.products.list.find(
          (product) => product.id === widgetTemplate.productId,
        )?.name || "-"
      );
    },
    id: "product",
    isHidden: false,
  },
  {
    label: "Position",
    field: "position",
    id: "position",
    isHidden: false,
  },
  {
    label: "Show countdown timer",
    field: (widgetTemplate) =>
      widgetTemplate.isShowCountDownTimer ? "Yes" : "No",
    id: "showCountDownTimer",
    isHidden: false,
  },
  {
    label: "Show promocodes",
    field: (widgetTemplate) =>
      widgetTemplate.isPromocodesAvailable ? "Yes" : "No",
    id: "showPromocodes",
    isHidden: false,
  },
  {
    label: "Active",
    field: (widgetTemplate) => {
      return widgetTemplate.isActive ? "Yes" : "No";
    },
    id: "active",
    isHidden: false,
  },
  {
    label: "Default",
    field: (widgetTemplate) => {
      return widgetTemplate.isDefault ? "Yes" : "No";
    },
    id: "default",
    isHidden: false,
  },
];

export const getWidgetTemplatesTableColumns = (columns) => {
  return getColumns(columns, WIDGET_TEMPLATES_TABLE_DEFAULT_COLUMNS);
};
